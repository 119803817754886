import BigNumber from 'bignumber.js';
import { Multicall } from 'ethereum-multicall'
import { Configuration } from './Configuration';
import { Utilies } from './Utilies';

export const Fetcher = {
	multicaller: null,
	calls: null,
	result: null,
	generateCallContext: async function (connectedAddress, chainID) {
		this.calls = [];
		const priceOracleCalls = [];
		for (const market of Object.values(Configuration.banks)) {
			const { address, ABI, type } = Configuration.getProxyWithFToken(market.qToken.address, chainID);
			const proxyAddress = (address && type === Utilies.CHANNEL_CONTRACT.TRANSPARENT_UPGRADEABLE_PROXY) ? address : market.qToken.address;

			this.calls.push({
				reference: market.name + ".fToken",
				contractAddress: market.qToken.address,
				abi: await Utilies.loadABI(market.qToken.ABI),
				calls: [
					{ reference: market.name + '.fToken.getCash', methodName: 'getCash' },
					{ reference: market.name + '.fToken.supplyRatePerBlock', methodName: 'supplyRatePerBlock' },
					{ reference: market.name + '.fToken.balanceOfUnderlying.' + connectedAddress, methodName: 'balanceOfUnderlying', methodParameters: [connectedAddress] },
					{ reference: market.name + '.fToken.balanceOf.' + connectedAddress, methodName: 'balanceOf', methodParameters: [connectedAddress] }
				]
			});

			if (address && type === Utilies.CHANNEL_CONTRACT.TRANSPARENT_UPGRADEABLE_PROXY) {
				this.calls.push({
					reference: market.name + ".proxy",
					contractAddress: proxyAddress,
					abi: await Utilies.loadABI(Configuration.channel.contractABI),
					calls: [
						{ reference: market.name + '.proxy.storedTokenBalanceOf.' + connectedAddress, methodName: 'storedTokenBalanceOf', methodParameters: [connectedAddress] },
						{ reference: market.name + '.proxy.fTokenBalanceOf.' + connectedAddress, methodName: 'fTokenBalanceOf', methodParameters: [connectedAddress] }
					]
				});
			}

			if (!market.isStable && !market.isLPToken && !market.usePriceOracle) {
				this.calls.push({
					reference: market.name + ".price",
					contractAddress: market.pairForPrice.address,
					abi: await Utilies.loadABI(market.pairForPrice.ABI),
					calls: [{ reference: market.name + ".price.getReserves", methodName: "getReserves" }]
				});
			}

			if (!market.isNative) {
				this.calls.push({
					reference: market.name + ".token",
					contractAddress: market.address,
					abi: await Utilies.loadABI(market.ABI),
					calls: [
						{ reference: market.name + ".token.balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] },
						{ reference: market.name + ".token.allowance", methodName: "allowance", methodParameters: [connectedAddress, proxyAddress] }
					]
				});
			}

			if (market.isLPToken || market.usePriceOracle) {
				priceOracleCalls.push({ reference: market.name + ".priceOracle.getUnderlyingPrice", methodName: "getUnderlyingPrice", methodParameters: [market.qToken.address] });
			}
		}

		if (priceOracleCalls.length > 0) {
			this.calls.push({
				reference: "priceOracle",
				contractAddress: Configuration.currentNetwork(chainID).priceOracle.address,
				abi: await Utilies.loadABI(Configuration.currentNetwork(chainID).priceOracle.ABI),
				calls: priceOracleCalls
			});
		}
	},
	call: async function (multicallAddress, web3) {
		if (!this.multicaller) {
			this.multicaller = new Multicall({
				multicallCustomContractAddress: multicallAddress,
				web3Instance: web3
			});
		}

		try {
			this.result = await this.multicaller.call(this.calls);
			console.log("未处理请求结果", this.result);
		} catch (error) {
			return console.error(error);
		}
	},
	parseResult: function () {
		if (!this.result) {
			return null;
		}

		const data = {};
		const returns = Object.values(this.result.results);

		const returnsFToken = returns.filter(item => item.originalContractCallContext.reference.lastIndexOf(".fToken") > 0);
		for (const resultOfToken of returnsFToken) {
			for (const resultOfMethod of resultOfToken.callsReturnContext) {
				data[resultOfMethod.reference] = this.parseValues(resultOfMethod.returnValues);
			}
		}

		const returnsPrice = returns.filter(item => item.originalContractCallContext.reference.lastIndexOf(".price") > 0);
		for (const resultOfPrice of returnsPrice) {
			for (const resultOfMethod of resultOfPrice.callsReturnContext) {
				data[resultOfMethod.reference] = this.parseValues(resultOfMethod.returnValues);
			}
		}

		const returnsToken = returns.filter(item => item.originalContractCallContext.reference.lastIndexOf(".token") > 0);
		for (const resultOfToken of returnsToken) {
			for (const resultOfMethod of resultOfToken.callsReturnContext) {
				data[resultOfMethod.reference] = this.parseValues(resultOfMethod.returnValues);
			}
		}

		const returnsPriceOracle = returns.filter(item => item.originalContractCallContext.reference === "priceOracle");
		for (const resultOfPriceOracle of returnsPriceOracle) {
			for (const resultOfMethod of resultOfPriceOracle.callsReturnContext) {
				data[resultOfMethod.reference] = this.parseValues(resultOfMethod.returnValues);
			}
		}

		const returnsProxy = returns.filter(item => item.originalContractCallContext.reference.lastIndexOf(".proxy") > 0);
		for (const resultOfProxy of returnsProxy) {
			for (const resultOfMethod of resultOfProxy.callsReturnContext) {
				data[resultOfMethod.reference] = this.parseValues(resultOfMethod.returnValues);
			}
		}

		console.log("格式化请求结果", data);
		return data;
	},
	parseValues: function (value) {
		const parseValue = singleValue => {
			let parsed = null;
			switch (singleValue.type) {
				case "BigNumber":
					parsed = new BigNumber(singleValue.hex);
					break;
			}
			return parsed;
		};

		if (Array.isArray(value)) {
			const returns = [];
			for (const v of value) {
				returns.push(parseValue(v));
			}
			return returns;
		} else {
			return parseValue(value);
		}
	},
	getCash: function (data, token) {
		return data[token + ".fToken.getCash"][0];
	},
	getETHPrice: function (data, token) {
		return data[token + ".priceOracle.getUnderlyingPrice"][0];
	},
	getPrice: function (data, token, tokenDecimals, USDTDecimals, isRevert) {
		const reserves = data[token + ".price.getReserves"];
		if (isRevert) {
			return reserves[0].shiftedBy(-USDTDecimals).dividedBy(reserves[1].shiftedBy(-tokenDecimals)).toNumber();
		} else {
			return reserves[1].shiftedBy(-USDTDecimals).dividedBy(reserves[0].shiftedBy(-tokenDecimals)).toNumber();
		}
	},
	getBalance: function (data, token) {
		return data[token + ".token.balanceOf"][0];
	},
	getAllowance: function (data, token) {
		return data[token + ".token.allowance"][0];
	},
	getAPY: function (data, token, tokenDecimals, blockInterval) {
		const supplyRatePerBlock = data[token + ".fToken.supplyRatePerBlock"][0];
		return supplyRatePerBlock
			.shiftedBy(-tokenDecimals)
			.multipliedBy(Utilies.SECONDS_PER_DAY / blockInterval)
			.plus(1)
			.pow(Utilies.DAYS_PER_YEAR)
			.minus(1)
			.toNumber();
	},
	getSavingBalance: function (data, token, account) {
		const theProxy = Configuration.getProxyWithFToken(token.qToken.address, token.chainID);
		if (theProxy.address && theProxy.type === Utilies.CHANNEL_CONTRACT.TRANSPARENT_UPGRADEABLE_PROXY) {
			return data[token.name + ".proxy.storedTokenBalanceOf." + account][0];
		} else {
			return data[token.name + ".fToken.balanceOfUnderlying." + account][0];
		}
	},
	getSavingCTokenBalance: function (data, token, account) {
		const theProxy = Configuration.getProxyWithFToken(token.qToken.address, token.chainID);
		if (theProxy.address && theProxy.type === Utilies.CHANNEL_CONTRACT.TRANSPARENT_UPGRADEABLE_PROXY) {
			return data[token.name + ".proxy.fTokenBalanceOf." + account][0];
		} else {
			return data[token.name + ".fToken.balanceOf." + account][0];
		}
	}
};