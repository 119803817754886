import BigNumber from "bignumber.js";
import { useContext } from "react";
import { render } from "react-dom";
import { TwinLogo } from "../components/TwinLogo";
import { Configuration } from "../Configuration";
import { TheContext } from "../context";
import { Fetcher } from "../Fetcher";
import { Utilies } from "../Utilies";
import { DepositModal } from "./DepositModal";
import { WithdrawModal } from "./WithdrawModal";

export const Banks = props => {
	const context = useContext(TheContext.Web3Context);

	const handleDepsoit = event => {
		render(<DepositModal
			data={props.data[parseInt(event.currentTarget.id)]}
			context={context} />, document.getElementById("modalContainer"));
	};

	const handleWithdraw = event => {
		render(<WithdrawModal
			data={props.data[parseInt(event.currentTarget.id)]}
			context={context} />, document.getElementById("modalContainer"));
	};

	return (<table style={{ marginTop: "4em" }}>
		<thead>
			<tr className="tableHeader">
				<th>asset</th>
				<th>deposited</th>
				<th>wallet balance</th>
				<th>current interest</th>
			</tr>
		</thead>

		<tbody>
			{props.data && props.data.map((bank, index) => {
				const savingAPY = Utilies.formatPercent(bank.savingAPY);

				return (<tr key={bank.symbol}>
					<td>
						<div className="titleRow">
							<div style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center"
							}}>
								{bank.isLPToken ? (<TwinLogo
									logo1={bank.logos[0]}
									logo2={bank.logos[1]} />) : (<img
										className="logo"
										src={bank.logo}
										height="26px" />)}

								<span>&nbsp;{bank.name}</span>
							</div>

							<div className="interest">{savingAPY}</div>
						</div>
					</td>

					<td className="keyValueLabel">
						<div className="key">deposited</div>

						<div className="value">
							<div>{Utilies.formatFiat(bank.savingBalanceFiat)}</div>
							<div className="amountLabel">{Utilies.formatAmount(bank.savingBalance, bank.decimals)}&nbsp;{bank.symbol}</div>
						</div>
					</td>

					<td className="keyValueLabel">
						<div className="key">wallet balance</div>

						<div className="value">
							<div>{Utilies.formatFiat(bank.balance.shiftedBy(-bank.decimals).multipliedBy(bank.getPrice(context.networkID)))}</div>
							<div className="amountLabel">{Utilies.formatAmount(bank.balance, bank.decimals)}&nbsp;{bank.symbol}</div>
						</div>
					</td>

					<td className="interestLabel">{savingAPY}</td>

					<td>
						<div className="buttonCell">
							{bank.savingBalance.gt(0) && (<>
								<button id={index}
									className="normalButton"
									onClick={handleWithdraw}>
									<div>withdraw</div>
								</button>

								<span>&nbsp;</span>
							</>)}

							<button
								id={index}
								className="normalButton"
								onClick={handleDepsoit}>
								<div>deposit</div>
							</button>
						</div>
					</td>
				</tr>);
			})}
		</tbody>
	</table>)
};