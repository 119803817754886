import React, { useEffect, useState } from "react";

export const AmountInput = props => {
	const [inputValue, setInputValue] = useState(0);

	useEffect(() => {
		setInputValue(0);
	}, [props.id]);

	const handleClick = _ => {
		if (props.isApproved) {
			setInputValue(props.max);
			props.getValue(props.id, props.max);
		} else {
			props.approve(props.forToken);
		}
	}

	const handleChange = event => {
		const input = event.target.value
		const value = parseFloat(input);

		if (!isNaN(value)) {
			setInputValue(value);
			return props.getValue(props.id, value);
		}

		if (input === "") {
			setInputValue();
			return props.getValue(props.id, 0)
		}
	}

	return (<div className={"amountInputLayout" + (props.disabled ? " amountInputDisabled" : "")}>
		{props.logo && ((typeof props.logo === "string") ? (<img src={props.logo} height="24px" alt="coin logo" />) : props.logo)}

		<div>
			<input
				className="amountInput"
				value={inputValue}
				onChange={handleChange}
				disabled={!props.isApproved}
				type="number" />

			<div
				className="amountLabel"
				style={{
					fontSize: "10px",
					marginTop: "1px"
				}}>{"available" + ": " + props.max.toFixed() + " " + props.symbol}</div>
		</div>

		<button
			className="button"
			size="sm"
			onClick={handleClick}>
			{props.isApproved ? "Max" : "approve"}
		</button>
	</div>)
};