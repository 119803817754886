import { useEffect, useState } from 'react';
import './App.css';
import { Configuration } from './Configuration';
import { TheContext } from './context';
import { DataModel } from './DataModel';
import { Fetcher } from './Fetcher';
import { SavesManager } from './SavesManager';
import { Utilies } from './Utilies';
import { Banks } from './views/Banks';
import { Footer } from './views/Footer';
import { Header } from './views/Header';
import { ScorePanel } from './views/ScorePanel';
import { Web3Manager } from './Web3Manager';

let mainLoop = null;

function App() {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState("");
  const [networkID, setNetworkID] = useState(0);
  const [data, setData] = useState(null);
  const [totalBalance, setTotalBalance] = useState(Utilies.ZERO);
  const [totalInterest, setTotalInterest] = useState(0);

  const handleConnect = chainID => {
    setNetworkID(chainID);
  };

  const handleSwithAccount = async account => {
    setAccount(account);
  }

  const loadData = async () => {
    if (mainLoop) {
      clearTimeout(mainLoop);
      mainLoop = null;
    }

    await Fetcher.call(Configuration.currentNetwork(networkID).multicall, web3);
    const result = Fetcher.parseResult();

    DataModel.init();
    const final = await DataModel.combine(result, networkID, account);
    console.log("最终数据", final);
    setData(final);

    setTotalBalance(DataModel.totalBalance);
    setTotalInterest(DataModel.totalInterest);

    mainLoop = setTimeout(() => {
      if (account && networkID > 0 && web3) {
        loadData();
      }
    }, 15000);
  };

  const initWeb3 = async () => {
    const { web3, account, chainID } = await Web3Manager.init(handleConnect, handleSwithAccount);
    setWeb3(web3);
    setNetworkID(chainID);
    setAccount(account);
  }

  useEffect(async () => {
    SavesManager.init();

    const channel = new URL(window.location).searchParams.get("c")
    await Configuration.init(channel);

    await initWeb3();
  }, []);

  useEffect(async () => {
    if (account && networkID > 0, web3) {
      await Configuration.loadConfigWithNetwork(networkID);
      await Fetcher.generateCallContext(account, networkID);

      loadData();
    }
  }, [account, networkID, web3])

  return (
    <TheContext.Web3Context.Provider value={{ web3, account, networkID }}>
      <div className='app'>
        <div className='mainContainer'>
          <Header />

          <div className='main'>
            <ScorePanel
              totalBalance={totalBalance}
              totalInterest={totalInterest} />

            {data && data.length > 0 ? (<Banks
              chainID={networkID}
              data={data} />) : (<div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20em"
              }}>
                <img src='/images/loading.gif' width="50px" alt="loading..." />
              </div>)}
          </div>

          <Footer />
        </div>
      </div>
    </TheContext.Web3Context.Provider>
  );
}

export default App;
