export const SavesManager = {
	KEY_DEFAULT_NETWORK: "defaultNetwork",
	defaultNetwork: 0,
	init: function () {
		let result = this.load(this.KEY_DEFAULT_NETWORK);
		if (result) {
			this.defaultNetwork = parseInt(result);
		} else {
			this.save(this.KEY_DEFAULT_NETWORK, this.defaultNetwork);
		}
	},
	load: function (key) {
		return window.localStorage.getItem(key);
	},
	save: function (key, value) {
		return window.localStorage.setItem(key, value);
	}
};