import { unmountComponentAtNode } from "react-dom";
import { Modal } from "../components/Modal";
import { Configuration } from "../Configuration";
import { Web3Manager } from "../Web3Manager";

export const SwitchNetwork = props => {
	const networks = Object.values(Configuration.networks.networks) || [];

	const close = () => {
		unmountComponentAtNode(document.getElementById("modalContainer"));
	};

	const handleClose = event => {
		close();
	};

	const handleSwitchNetwork = async event => {
		close();
		await Web3Manager.switchNetwork(networks[parseInt(event.currentTarget.id)]);
	};

	return (<Modal>
		<div className="modalLayout">
			<div style={{
				position: "absolute",
				right: "1em",
				top: "1em"
			}}>
				<button className="smallButton" onClick={handleClose}>✖︎</button>
			</div>

			<div style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center"
			}}>
				<h3>networks</h3>

				<div style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center"
				}}>
					{networks.map((network, index) => {
						return (<>
							<div>&nbsp;</div>

							<button
								id={index}
								onClick={handleSwitchNetwork}
								className="button networkItem">
								<img src={network.logo} width="16px" alt="logo" />
								<span className='label'>{network.name}</span>
							</button>
						</>)
					})}
				</div>

				<div style={{
					marginTop: "3em"
				}}>
					<button
						onClick={handleClose}
						className="normalButton">
						<div>cancel</div>
					</button>
				</div>
			</div>
		</div>
	</Modal>)
};