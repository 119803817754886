import { useContext } from "react";
import { render } from "react-dom";
import { Configuration } from "../Configuration";
import { TheContext } from "../context";
import { Utilies } from "../Utilies";
import { SwitchNetwork } from "./SwitchNetwork";

export const Header = props => {
	const web3Context = useContext(TheContext.Web3Context);

	const handleSwitchNetwork = event => {
		render(<SwitchNetwork />, document.getElementById("modalContainer"));
	};

	return (
		<div className='titleBarLayout'>
			<div>
				<a
					href="/"
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
						columnGap: "0.5em"
					}}>
					<img
						src={Configuration.channel ? Configuration.channel.logo : "/images/filda_logo.png"}
						width="32px"
						alt="logo" />

					<span className='title'>{Configuration.channel ? Configuration.channel.name : "filda"}</span>

					<span className='title' style={{ fontWeight: 900 }}>Essentials</span>
				</a>
			</div>

			<div style={{
				display: "flex",
				flexDirection: "row",
				columnGap: "1em"
			}}>
				<div style={{
					display: "flex",
					flexDirection: "row",
					columnGap: "1em"
				}}>
					{web3Context.networkID > 0 && Configuration.networks && (<button
						onClick={handleSwitchNetwork}
						className="buttonSwitchNetwork">
						<img src={Configuration.currentNetwork(web3Context.networkID).logo} width="16px" alt="logo" />
						<span className='label'>{Configuration.currentNetwork(web3Context.networkID).name}</span>
					</button>)}

					{web3Context.account && (<button className="buttonAccountLabel">
						<span className='label'>{Utilies.truncate(web3Context.account, 12, "...")}</span>
					</button>)}
				</div>

				<a href='https://www.filda.io' className='button' target="_blank" rel="noreferrer">
					<img src='/images/filda_logo.png' width="16px" alt="filda_logo" />
					<span className='label'>Filda</span>
					<img src='/images/share.png' width="16px" alt="share" />
				</a>
			</div>
		</div>
	);
};