import BigNumber from "bignumber.js";
import { Configuration } from "./Configuration";

export const Utilies = {
	SECONDS_PER_DAY: 86400,
	DAYS_PER_YEAR: 364,
	ZERO: new BigNumber(0),
	MAX: new BigNumber(2).pow(256).minus(1),
	TRANSACTION_STATUS: {
		IDLE: 0,
		SENT: 1,
		CONFIRMING: 2,
		DONE: 3
	},
	CHANNEL_CONTRACT: {
		TRANSPARENT_UPGRADEABLE_PROXY: "TransparentUpgradeableProxy",
		CHANNEL: "channel"
	},
	truncate: function (fullStr, strLen, separator) {
		if (fullStr.length <= strLen) return fullStr;

		separator = separator || '...';

		var sepLen = separator.length,
			charsToShow = strLen - sepLen,
			frontChars = Math.ceil(charsToShow / 2),
			backChars = Math.floor(charsToShow / 2);

		return fullStr.substr(0, frontChars) + separator + fullStr.substr(fullStr.length - backChars);
	},
	loadABI: async function (url) {
		return await (await fetch(url)).json();
	},
	formatAmount: function (amount, decimals, fractionDigits) {
		return amount.shiftedBy(-decimals).toFixed(fractionDigits > 0 ? fractionDigits : Configuration.defaultAmountDecimals);
	},
	formatFiat: function (value, fractionDigits) {
		const v = (typeof value) === "object" ? value.toNumber() : value
		return v.toLocaleString("en-US", {
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: fractionDigits > 0 ? fractionDigits : Configuration.defaultFiatDecimals
		});
	},
	formatPercent: function (value, fractionDigits) {
		return value.toLocaleString("en-US", {
			style: "percent",
			maximumFractionDigits: fractionDigits > 0 ? fractionDigits : Configuration.defaultFiatDecimals
		});
	}
};