import { Utilies } from "./Utilies";

export const Configuration = {
	defaultAmountDecimals: 6,
	defaultFiatDecimals: 2,
	networks: null,
	banks: null,
	channel: null,
	init: async function (channel) {
		let channelResult = null;
		try {
			if (channel) {
				channelResult = await (await fetch("config/channels/" + channel + ".json")).json();
			}

			this.networks = await (await fetch("/config/networks.json")).json();
		} catch (error) {
			console.error(error);
		}

		if (channel && channelResult && channelResult.online) {
			this.channel = channelResult;
		}
	},
	loadConfigWithNetwork: async function (chainID) {
		this.banks = await (await fetch("/config/banks/" + chainID + ".json")).json();
	},
	currentNetwork: function (chainID) {
		return this.networks.networks[chainID];
	},
	getProxyWithFToken: function (fToken, chainID) {
		if (this.channel && this.channel.contract == Utilies.CHANNEL_CONTRACT.TRANSPARENT_UPGRADEABLE_PROXY && this.channel.tokens[chainID]) {
			return {
				address: this.channel.tokens[chainID][fToken],
				ABI: this.channel.contractABI,
				type: Utilies.CHANNEL_CONTRACT.TRANSPARENT_UPGRADEABLE_PROXY
			};
		}

		if (this.channel && this.channel.contract === Utilies.CHANNEL_CONTRACT.CHANNEL) {
			return {
				address: this.channel.contractAddress,
				ABI: this.channel.contractABI,
				type: Utilies.CHANNEL_CONTRACT.CHANNEL
			};
		}
	}
};