import { Utilies } from "../Utilies";

export const ScorePanel = props => {
	return (
		<div className="menuLayout">
			<div className="panel">
				<div className="grayLabel">Your Balance</div>
				<div className="scoreLabel">{Utilies.formatFiat(props.totalBalance)}</div>
			</div>

			<div className="panel">
				<div className="grayLabel">Interest</div>
				<div className="scoreLabel">{Utilies.formatPercent(props.totalInterest)}</div>
			</div>

			{/* <div className="panel" style={{
				flexDirection: "row",
				columnGap: "1em"
			}}>
				<img src="/filda.svg" width="28px" alt="filda" />

				<div className="grayLabel">Filda rewards</div>

				<div style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center"
				}}>
					<div className="scoreLabel">$ 3,435.86</div>
					<div className="smallGrayLabel">0.00846622 filda</div>
				</div>

				<button className="normalButton">
					<div>Collect</div>
				</button>
			</div> */}
		</div>
	);
};