import React from "react";

export const TwinLogo = ({
	logo1,
	logo2,
}) => {
	return (
		<div className="twinLogoLayout">
			<img className="logo1" src={logo1} />
			<img className="logo2" src={logo2} />
		</div>
	)
};