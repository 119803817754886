import Web3 from "web3";

export const Web3Manager = {
	provider: null,
	web3: null,
	init: async function (handleConnect, handleSwithAccount) {
		if (window.ethereum) {
			this.provider = window.ethereum;

			window.ethereum.on("connect", connectInfo => {
				return handleConnect(parseInt(connectInfo.chainId, 16));
			});

			window.ethereum.on("accountsChanged", accounts => {
				return handleSwithAccount(accounts[0]);
			});

			window.ethereum.on("chainChanged", chainId => {
				return handleConnect(parseInt(chainId, 16));
			});

			const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

			handleSwithAccount(accounts[0]);

			const chainID = parseInt(window.ethereum.chainId, 16);
			handleConnect(chainID);

			this.web3 = new Web3(this.provider);
			return {
				web3: this.web3,
				account: accounts[0],
				chainID
			};
		}
	},
	getWeb3: function () {
		if (window.ethereum) {
			this.web3 = new Web3(window.ethereum);
			return this.web3;
		}
	},
	getBalance: async function (account) {
		return await this.web3.eth.getBalance(account);
	},
	switchNetwork: async function (network) {
		if (window.ethereum) {
			try {
				await window.ethereum.request({
					method: 'wallet_switchEthereumChain',
					params: [{ chainId: Web3.utils.toHex(network.chainID) }],
				});

				// if (window.navigator.userAgent.includes("TokenPocket")) {
				// 	window.location.reload();
				// }
				window.location.reload();
			} catch (switchError) {
				if (switchError.code === 4902) {
					try {
						await window.ethereum.request({
							method: 'wallet_addEthereumChain',
							params: [{
								chainId: Web3.utils.toHex(network.chainID),
								rpcUrls: network.rpc,
								chainName: network.name,
								blockExplorerUrls: network.explorer,
								nativeCurrency: {
									name: network.currency.name,
									symbol: network.currency.symbol,
									decimals: network.currency.decimals
								}
							}]
						});
					} catch (addError) {
						console.error(addError)
					}
				} else {
					console.error(switchError)
				}
			}
		}
	}
};