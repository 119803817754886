import BigNumber from "bignumber.js";
import { useState } from "react";
import { unmountComponentAtNode } from "react-dom";
import { AmountInput } from "../components/AmountInput";
import { Modal } from "../components/Modal";
import { TwinLogo } from "../components/TwinLogo";
import { Configuration } from "../Configuration";
import { Utilies } from "../Utilies";

export const DepositModal = props => {
	const [depositValue, setDepositValue] = useState(Utilies.ZERO);
	const [txHash, setTxHash] = useState("");
	const [transactionStatus, setTransactionStatus] = useState(Utilies.TRANSACTION_STATUS.IDLE);

	const handleGetValue = (id, value) => {
		setDepositValue(BigNumber(value).shiftedBy(props.data.decimals));
	}

	const txCallback = tx => {
		if (tx) {
			setTxHash(tx);
			setTransactionStatus(Utilies.TRANSACTION_STATUS.SENT);
		}
	}

	const approvedCallback = () => {
		props.data.allowance = Utilies.MAX;
		setTransactionStatus(Utilies.TRANSACTION_STATUS.IDLE);
	};

	const doneCallback = () => {
		setTransactionStatus(Utilies.TRANSACTION_STATUS.DONE);

		setTimeout(() => {
			setDepositValue(Utilies.ZERO);
			setTxHash("");
			setTransactionStatus(Utilies.TRANSACTION_STATUS.IDLE);
			close();
		}, 3000);
	}

	const handleApprove = forToken => {
		return props.data.approve(props.context.web3, props.context.account, txCallback, approvedCallback);
	}

	const handleDeposit = event => {
		// web3, account, amount, txCallback, doneCallback
		return props.data.deposit(
			props.context.web3,
			props.context.account,
			depositValue,
			txCallback,
			doneCallback
		);
	}

	const close = () => {
		unmountComponentAtNode(document.getElementById("modalContainer"));
	};

	const handleClose = event => {
		close();
	};

	return (<Modal>
		<div className="modalLayout">
			<div style={{
				position: "absolute",
				right: "1em",
				top: "1em"
			}}>
				<button className="smallButton" onClick={handleClose}>✖︎</button>
			</div>

			<div style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center"
			}}>
				<div style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center"
				}}>
					{props.data.isLPToken ? (<TwinLogo
						logo1={props.data.logos[0]}
						logo2={props.data.logos[1]} />) : (<img src={props.data.logo} width="24px" />)}

					<span>&nbsp;{props.data.name}</span>
				</div>

				<h3>deposit to savings account</h3>

				{transactionStatus === Utilies.TRANSACTION_STATUS.DONE && (<>
					<p />
					<img src="/images/check.png" width="50px" alt="done!" />
					<p />
					<div>Successfully Deposited</div>
					<p />
				</>)}

				{transactionStatus === Utilies.TRANSACTION_STATUS.SENT && (<>
					<p />

					<img src="/images/loading.gif" width="50px" alt="loading..." />

					{txHash && (<a href={Configuration.currentNetwork(props.context.networkID).explorerURL + "/" + txHash} className='button' target="_blank" rel="noreferrer">
						<span className='label'>{Utilies.truncate(txHash, 20, "...")}</span>
						<img src='/images/share.png' width="16px" alt="share" />
					</a>)}
				</>)}

				{transactionStatus === Utilies.TRANSACTION_STATUS.IDLE && (<>
					<p />

					<AmountInput
						id={props.data.symbol}
						max={props.data.balance.shiftedBy(-props.data.decimals)}
						disabled={props.data.balance.eq(0)}
						logo={props.data.logo}
						symbol={props.data.symbol}
						getValue={handleGetValue}
						isApproved={props.data.allowance.gt(0)}
						approve={handleApprove} />
				</>)}

				<div style={{
					marginTop: "5em"
				}}>
					<button
						onClick={handleClose}
						className="normalButton">
						<div>cancel</div>
					</button>

					<span>&nbsp;&nbsp;</span>

					<button
						className="normalButton"
						disabled={depositValue.eq(0) || transactionStatus != Utilies.TRANSACTION_STATUS.IDLE}
						onClick={handleDeposit}>
						<div>deposit</div>
					</button>
				</div>
			</div>
		</div>
	</Modal>);
};