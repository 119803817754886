import BigNumber from "bignumber.js";
import { useState } from "react";
import { unmountComponentAtNode } from "react-dom";
import { AmountInput } from "../components/AmountInput";
import { Modal } from "../components/Modal";
import { Configuration } from "../Configuration";
import { Utilies } from "../Utilies";

export const WithdrawModal = props => {
	const [depositValue, setDepositValue] = useState(Utilies.ZERO);
	const [depositRawValue, setDepositRawValue] = useState(Utilies.ZERO);
	const [txHash, setTxHash] = useState("");
	const [transactionStatus, setTransactionStatus] = useState(Utilies.TRANSACTION_STATUS.IDLE);

	const handleGetValue = (id, value) => {
		const rate = BigNumber(value)
			.shiftedBy(props.data.decimals)
			.dividedBy(props.data.savingBalance);
		setDepositRawValue(rate.multipliedBy(props.data.savingBalance));
		setDepositValue(rate.multipliedBy(props.data.savingCTokenBalance));
	}

	const txCallback = tx => {
		if (tx) {
			setTxHash(tx);
			setTransactionStatus(Utilies.TRANSACTION_STATUS.SENT);
		}
	}

	const doneCallback = () => {
		setTransactionStatus(Utilies.TRANSACTION_STATUS.DONE);

		setTimeout(() => {
			setDepositValue(Utilies.ZERO);
			setTxHash("");
			setTransactionStatus(Utilies.TRANSACTION_STATUS.IDLE);
			close();
		}, 3000);
	}

	const handleWithdraw = event => {
		// web3, account, amount, txCallback, doneCallback
		return props.data.withdraw(
			props.context.web3,
			props.context.account,
			depositValue.toFixed(0, 1),
			depositRawValue.toFixed(0, 1),
			txCallback,
			doneCallback
		);
	}

	const close = () => {
		unmountComponentAtNode(document.getElementById("modalContainer"));
	};

	const handleClose = event => {
		close();
	};

	return (<Modal>
		<div className="modalLayout">
			<div style={{
				position: "absolute",
				right: "1em",
				top: "1em"
			}}>
				<button className="smallButton" onClick={handleClose}>✖︎</button>
			</div>

			<div style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center"
			}}>
				<div style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center"
				}}>
					{props.data.isLPToken ? (<TwinLogo
						logo1={props.data.logos[0]}
						logo2={props.data.logos[1]} />) : (<img src={props.data.logo} width="24px" />)}

					<span>&nbsp;{props.data.name}</span>
				</div>

				<h3>Withdraw Assets</h3>

				{transactionStatus === Utilies.TRANSACTION_STATUS.DONE && (<>
					<p />
					<img src="/images/check.png" width="50px" alt="done!" />
					<p />
					<div>Successfully Withdrew Assets</div>
					<p />
				</>)}

				{transactionStatus === Utilies.TRANSACTION_STATUS.SENT && (<>
					<p />

					<img src="/images/loading.gif" width="50px" alt="loading..." />

					{txHash && (<a href={Configuration.currentNetwork(props.context.networkID).explorerURL + "/" + txHash} className='button' target="_blank" rel="noreferrer">
						<span className='label'>{Utilies.truncate(txHash, 20, "...")}</span>
						<img src='/images/share.png' width="16px" alt="share" />
					</a>)}
				</>)}

				{transactionStatus === Utilies.TRANSACTION_STATUS.IDLE && (<>
					<p />

					<div style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						width: "80%"
					}}>
						<div className="smallGrayLabel">saving balance</div>
						<div>{Utilies.formatAmount(props.data.savingBalance, props.data.decimals)}&nbsp;{props.data.symbol}</div>
					</div>

					<p />

					<AmountInput
						id={props.data.symbol}
						max={props.data.savingBalance.shiftedBy(-props.data.decimals)}
						disabled={props.data.savingBalance.eq(0)}
						logo={props.data.logo}
						symbol={props.data.symbol}
						getValue={handleGetValue}
						isApproved={true} />
				</>)}

				<div style={{
					marginTop: "5em"
				}}>
					<button
						onClick={handleClose}
						className="normalButton">
						<div>cancel</div>
					</button>

					<span>&nbsp;&nbsp;</span>

					<button
						className="normalButton"
						disabled={depositValue.eq(0) || transactionStatus != Utilies.TRANSACTION_STATUS.IDLE}
						onClick={handleWithdraw}>
						<div>withdraw</div>
					</button>
				</div>
			</div>
		</div>
	</Modal>);
};