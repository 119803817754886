import BigNumber from "bignumber.js";
import { Configuration } from "./Configuration";
import { Fetcher } from "./Fetcher";
import { Utilies } from "./Utilies";
import { Web3Manager } from "./Web3Manager";

export const DataModel = {
	totalBalance: Utilies.ZERO,
	totalInterest: 0,
	init: function () {
		this.totalBalance = Utilies.ZERO;
		this.totalInterest = 0;
	},
	approve: async function (web3, account, txCallback, doneCallback) {
		const abi = await Utilies.loadABI(this.ABI);
		const contract = new web3.eth.Contract(abi, this.address);
		const spender = Configuration.getProxyWithFToken(this.qToken.address, this.chainID) ?? this.qToken.address;
		return contract.methods.approve(spender, Utilies.MAX).send({ from: account }).on('transactionHash', function (hash) {
			if (txCallback) {
				txCallback(hash)
			}
		}).on('confirmation', function (confirmationNumber, receipt) {
			if (doneCallback && confirmationNumber === 24) {
				doneCallback(receipt);
			}
		}).on('receipt', function (receipt) {
			if (doneCallback && receipt) {
				doneCallback(receipt);
			}
		}).on('error', function (error, receipt) {
			// 
		});
	},
	deposit: async function (web3, account, amount, txCallback, doneCallback) {
		let abi = null;
		let contract = null;
		let func = null;
		let args = [];
		const params = { from: account };
		const { address, ABI, type } = Configuration.getProxyWithFToken(this.qToken.address, this.chainID);;
		if (address && ABI) {
			abi = await Utilies.loadABI(ABI);
			contract = new web3.eth.Contract(abi, address);
			func = contract.methods.deposit;

			if (type === Utilies.CHANNEL_CONTRACT.TRANSPARENT_UPGRADEABLE_PROXY) {
				args = [amount.toFixed()];
			}

			if (type === Utilies.CHANNEL_CONTRACT.CHANNEL) {
				args = [this.qToken.address, amount.toFixed()];
			}

			if (this.isNative) {
				params.value = amount.toFixed();
			}
		} else {
			abi = await Utilies.loadABI(this.qToken.ABI);
			contract = new web3.eth.Contract(abi, this.qToken.address);
			func = contract.methods.mint;

			if (this.isNative) {
				params.value = amount.toFixed();
			} else {
				args = [amount.toFixed()];
			}
		}

		// return func(...args).estimateGas(params);
		return func(...args).send(params).on('transactionHash', function (hash) {
			if (txCallback) {
				txCallback(hash)
			}
		}).on('confirmation', function (confirmationNumber, receipt) {
			if (doneCallback && confirmationNumber === 24) {
				doneCallback(receipt);
			}
		}).on('receipt', function (receipt) {
			if (doneCallback && receipt) {
				doneCallback(receipt);
			}
		}).on('error', function (error, receipt) {
			// 
		});
	},
	withdraw: async function (web3, account, fTokenAmount, rawAmount, txCallback, doneCallback) {
		// const abi = await Utilies.loadABI(this.qToken.ABI);
		// const contract = new web3.eth.Contract(abi, this.qToken.address);
		// const func = contract.methods.redeem;
		let abi = null;
		let contract = null;
		let args = [];
		let func = null;
		const params = { from: account };
		const { address, ABI, type } = Configuration.getProxyWithFToken(this.qToken.address, this.chainID);
		if (address && ABI) {
			abi = await Utilies.loadABI(ABI);
			contract = new web3.eth.Contract(abi, address);

			if (type === Utilies.CHANNEL_CONTRACT.TRANSPARENT_UPGRADEABLE_PROXY) {
				func = contract.methods.redeem;
				args.push(this.qToken.address, rawAmount);
			}

			if (type === Utilies.CHANNEL_CONTRACT.CHANNEL) {
				func = contract.methods.userWithdraw;
				args.push(rawAmount);
			}
		} else {
			abi = await Utilies.loadABI(this.qToken.ABI);
			contract = new web3.eth.Contract(abi, this.qToken.address);

			if (!this.isNative) {
				func = contract.methods.redeem;
				args.push(fTokenAmount);
			} else {
				func = contract.methods.redeemUnderlying;
				args.push(rawAmount);
			}
		}

		// return func(...args).estimateGas(params);
		return func(...args).send(params).on('transactionHash', function (hash) {
			if (txCallback) {
				txCallback(hash)
			}
		}).on('confirmation', function (confirmationNumber, receipt) {
			if (doneCallback && confirmationNumber === 24) {
				doneCallback(receipt);
			}
		}).on('receipt', function (receipt) {
			if (doneCallback && receipt) {
				doneCallback(receipt);
			}
		}).on('error', function (error, receipt) {
			// 
		});
	},
	getPrice: function (chainID) {
		if (this.ETHPrice && this.ETHPrice.gt(0)) {
			const theNetwork = Configuration.currentNetwork(chainID);
			return this.ETHPrice.shiftedBy(-theNetwork.currency.decimals).multipliedBy(theNetwork.currency.price);
		} else {
			return this.price
		}
	},
	// getLPTokenData: async function (bank, chainID, allBank) {
	// 	const dQuick = "0xf28164a485b0b2c90639e47b0f377b4a438a16b1";
	// 	let lpTotalAPY = 0;
	// 	const decimal = 8;

	// 	if (bank.lpRewardsTokens && bank.lpRewardsTokens.length > 0) {
	// 		let lpPrice = bank.getPrice(chainID);
	// 		lpPrice = BigNumber(lpPrice).shiftedBy(decimal).toFixed(0)

	// 		let priceArr = []
	// 		for (let i = 0; i < bank.lpRewardsTokens.length; i++) {
	// 			const lpRewardsSymbol = bank.lpRewardsTokens[i].name
	// 			let price = allBank.find(item => item.name === lpRewardsSymbol).getPrice();
	// 			price = BigNumber(price).shiftedBy(decimal).toFixed(0);
	// 			priceArr.push(price)
	// 		}

	// 		const contract = new Web3Manager.web3.eth.Contract(
	// 			await Utilies.loadABI(Configuration.currentNetwork(chainID).compoundLens.ABI),
	// 			Configuration.currentNetwork(chainID).compoundLens.address
	// 		);

	// 		if (bank.lpRewardsTokens.length === 1) {
	// 			// 单挖
	// 			lpTotalAPY = await contract.methods.getQuickLpAPY(bank.qToken.address, dQuick, ...priceArr, lpPrice).call();
	// 		} else {
	// 			// 双挖
	// 			const lpTotalAPYJson = await contract.methods.getQuickDualLpAPY(bank.qToken.address, dQuick, ...priceArr, lpPrice).call();
	// 			for (let key in lpTotalAPYJson) {
	// 				if (isNaN(parseInt(key))) {
	// 					continue;
	// 				}
	// 				lpTotalAPY += parseInt(lpTotalAPYJson[key])
	// 			}
	// 		}

	// 		const valueA = BigNumber(lpTotalAPY).shiftedBy(-decimal);
	// 		lpTotalAPY = +(((BigNumber(1).plus(valueA))).pow(364).minus(1)).toNumber();
	// 	}

	// 	return lpTotalAPY;
	// },
	combine: async function (data, chainID, account) {
		let interest = 0;
		const banks = Configuration.banks ? [...(Object.values(Configuration.banks))] : [];
		for (const bank of banks) {
			bank.chainID = chainID;

			if (bank.isLPToken || bank.usePriceOracle) {
				bank.ETHPrice = Fetcher.getETHPrice(data, bank.name);
			} else if (!bank.isStable) {
				bank.price = Fetcher.getPrice(data, bank.name, bank.decimals, bank.pairForPrice.USDTDecimals, bank.pairForPrice.isRevert);
			} else {
				bank.price = 1;
			}

			// bank.deposited = Fetcher.getCash(data, bank.name);
			bank.savingBalance = Fetcher.getSavingBalance(data, bank, account);
			bank.savingCTokenBalance = Fetcher.getSavingCTokenBalance(data, bank, account);

			if (bank.isNative) {
				const balanceResult = await Web3Manager.getBalance(account);
				bank.balance = new BigNumber(balanceResult);

				bank.allowance = Utilies.MAX;

				Configuration.currentNetwork(chainID).currency.price = bank.price;
			} else {
				bank.balance = Fetcher.getBalance(data, bank.name);
				bank.allowance = Fetcher.getAllowance(data, bank.name);
			}

			bank.deposit = this.deposit;
			bank.withdraw = this.withdraw;
			bank.approve = this.approve;
			bank.getPrice = this.getPrice;

			// if (bank.isLPToken) {
			// 	bank.savingAPY = await DataModel.getLPTokenData(bank, chainID, banks);
			// } else {
			// 	bank.savingAPY = Fetcher.getAPY(data, bank.name, bank.qToken.decimals, Configuration.currentNetwork(chainID).blockInterval);
			// }
			bank.savingAPY = Fetcher.getAPY(data, bank.name, bank.qToken.decimals, Configuration.currentNetwork(chainID).blockInterval);

			// bank.balanceFiat = bank.balance.shiftedBy(-bank.decimals).multipliedBy(bank.price);
			// this.totalBalance = this.totalBalance.plus(bank.balanceFiat);
			bank.savingBalanceFiat = bank.savingBalance.shiftedBy(-bank.decimals).multipliedBy(bank.getPrice(chainID));
			this.totalBalance = this.totalBalance.plus(bank.savingBalanceFiat);

			if (bank.savingBalance.gt(0)) {
				interest += bank.savingAPY;
			}
		}

		this.totalInterest = interest / banks.length;

		return banks;
	}
};